import Cookies from 'js-cookie'

export const form = {
  namespaced: true,
  state: {
    usLocations: [],
    usCities: [],
    usStates: [],
    practiceAreas: [],
    marketingCompanies: [],
    bulkFormsEnqueued: false,
    loadingData: false,
    blacklistedDomains: []
  },
  mutations: {
    setUsLocations (state, list) {
      state.usLocations = list
    },
    setUsCities (state, list) {
      state.usCities = []
      list.forEach(el => {
        const pieces = el.split(',')
        const city = pieces[0]
        state.usCities.push(city)
      })
    },
    setUsStates (state, list) {
      state.usStates = []
    },
    setPracticeAreas (state, areas) {
      state.practiceAreas = areas
    },
    setBulkFormsEnqueued (state, bool) {
      state.bulkFormsEnqueued = bool
    },
    setMarketingCompanies (state, companies) {
      state.marketingCompanies = companies
    },
    setLoadingData (state, bool) {
      state.loadingData = bool
    },
    setBlacklistedDomains (state, domains) {
      state.blacklistedDomains = domains
    }
  },
  actions: {
    setLoadingData ({ commit }, bool) {
      commit('setLoadingData', bool)
    },
    clearData ({ commit }) {
      commit('setMarketingCompanies', [])
      commit('setPracticeAreas', [])
      commit('setUsLocations', [])
      commit('setUsCities', [])
      commit('setUsStates', [])
      commit('setBlacklistedDomains', [])
    },
    async setData ({ commit, state, rootState }) {
      const hasArcTokens = Cookies.get('_.arc_score.arcScoreKey') !== 'undefined' &&
        typeof Cookies.get('_.arc_score.arcScoreKey') !== 'undefined'
      let isGuest = Cookies.get('_.arc_score.isGuest')
      isGuest === 'true' ? isGuest = true : isGuest = false
      if (hasArcTokens && !isGuest) {
        const hasCanadianLocations = state.usLocations.some(location => location.includes(',Canada'))
        if (
          (state.usLocations.length === 0 ||
          state.blacklistedDomains.length === 0) ||
          !hasCanadianLocations
        ) {
          commit('setLoadingData', true)

          const promises = [
            this._vm.$arc.get('dfs/locations'),
            this._vm.$arc.get('domains/blacklist')
          ]

          const resp = await Promise.all(promises)

          console.log(resp[0])

          resp[0].data?.locations?.length > 0 ? commit('setUsLocations', resp[0].data.locations.map(location => {
            return location.name
          })) : commit('setUsLocations', [])

          resp[1].data?.domains?.length > 0 ? commit('setBlacklistedDomains', resp[1].data.domains.map(domain => {
            return domain.name
          })) : commit('setBlacklistedDomains', [])

          commit('setLoadingData', false)
        } else {
          commit('setLoadingData', false)
        }

        const { data } = await this._vm.$arc.get('practice_areas')
        if (data.practice_areas) {
          const practiceAreas = data.practice_areas.map(area => {
            return area.name
          })
          commit('setPracticeAreas', practiceAreas)
        }

        commit('setLoadingData', false)
      }
    },
    setBulkFormsEnqueued ({ commit }, bool) {
      commit('setBulkFormsEnqueued', bool)
    }
  },
  getters: {
    allUsLocations: (state) => state.usLocations,
    allUsCities: (state) => state.usCities,
    allUsStates: (state) => state.usStates,
    allPracticeAreas: (state) => state.practiceAreas,
    getBulkFormsStatus: (state) => state.bulkFormsEnqueued,
    allBlacklistedDomains: (state) => state.blacklistedDomains
  }
}
