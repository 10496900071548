<template>
  <BaseLayout
    title="New City A.R.C Report"
    :customClasses="['city']"
    ref="baseLayout">
    <template v-slot:page-header>
      <action-links
        v-if="!loading && sortedResults.length > 0">
        <Button
          @click="runNewSearch"
          text="Run New Search" />
      </action-links>
    </template>
    <Form
      @submit.prevent="enqueueTask(practiceArea, location)"
      ref="taskSearch">
      <Dropdown
        :options="filteredLocations"
        :isSearch="true"
        dropdownPlaceholder="Start typing location"
        :inputHasLabel="true"
        inputId="city"
        inputLabelText="Select city"
        @select="location = $event"
        @input="debounceLocationName($event)"
        :inputVal="location"
        :isDisabled="formDisabled" />
      <Dropdown
        :hasIcon="true"
        :options="formData.practiceAreas"
        dropdownPlaceholder="Practice Area"
        :inputHasLabel="true"
        inputId="practiceArea"
        inputLabelText="Select practice area"
        @select="practiceArea = $event"
        @input="practiceArea = $event"
        :inputVal="practiceArea"
        :isDisabled="formDisabled" />
      <Button
        text="Submit"
        type="submit"
        :isDisabled="formDisabled" />
    </Form>
    <div
      class="validation-messages"
      v-if="formError">
      <p
        v-for="(error, i) in formErrors"
        :key="i">
        {{ error }}
      </p>
    </div>
    <Loading
      v-show="loading"
      text="collecting data" />
    <Table
      :loading="loading"
      v-show="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('domain')"
            :class="sortedClass('domain')"
            :hasTip="true"
            thTip="(Click to view domain profile)">
            Domain
          </t-heading>
          <t-heading>Location</t-heading>
          <t-heading>Tier</t-heading>
          <t-heading
            @click="sortBy('arc_score')"
            :class="sortedClass('arc_score')">
            ARC Score
          </t-heading>
          <t-heading
            :hasTip="false"
            thTip="(Double click to edit)">
            Marketing Co.
          </t-heading>
          <t-heading
            :textCentered="true"
            v-if="hasOutreachKey">
            Add Prospect
          </t-heading>
          <t-heading
            @click="sortBy('arc_rank')"
            :class="sortedClass('arc_rank')">
            ARC Rank
          </t-heading>
          <t-heading>Score Date</t-heading>
          <t-heading>Include</t-heading>
          <t-heading>View</t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(task, i) in sortedResults"
          :key="i">
          <t-cell>
            <router-link
              :to="{
                name: 'profile',
                query: {
                  domain: `${task.domain}`
                }
              }"
              target="_blank">
              {{ task.domain }}
            </router-link>
          </t-cell>
          <t-cell>{{ formattedLocation }}</t-cell>
          <t-cell>{{ tableData.market_tier }}</t-cell>
          <t-cell>{{ task.arc_score }}</t-cell>
          <t-cell
            :isEditable="false"
            @doneEditing="updateMarketingAgency(task, marketingCompany)"
            @dropdownInput="marketingCompany = $event"
            @dropdownSelect="marketingCompany = $event"
            :dropdownOptions="globalMarketingCompanies"
            customOption="name"
            :defaultValue="
              task.marketing_company === ''
              ? ''
              : task.marketing_company
            ">
            {{ task.marketing_company === 'null' ? '' : task.marketing_company }}
          </t-cell>
          <t-cell
            v-if="hasOutreachKey">
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="plus"
              :isRounded="true"
              :isSmall="true"
              btnType="purple"
              @click="toggleModal(i)"
              v-if="!isIncludedInOutreach(task.domain, i)" />
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="pen"
              :isRounded="true"
              :isSmall="true"
              btnType="blue"
              @click="toggleModal(i, true, task.outreachProfile)"
              v-if="isIncludedInOutreach(task.domain) && task.outreachProfile" />
          </t-cell>
          <t-cell>{{ task.arc_rank }}</t-cell>
          <t-cell>{{ tableData.completed_at | moment("M/DD/YYYY") }}</t-cell>
          <t-cell>
            <Input
              type="checkbox"
              :hasLabel="true"
              :inputId="`include-${task.id}`"
              :checked="isIncluded(task.id)"
              @click.prevent="includeOrExclude(task.domain, task.id)" />
          </t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{ path: `${task.domain_slug}` }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <OutreachModal
      v-if="hasOutreachKey"
      :selectedWebsite="selectedWebsite"
      :isActive="modalActive"
      :arcDetails="{
        location: formattedLocation,
        practiceArea: tableData.practice_area_name
      }"
      :mode="modalMode"
      @modal:closed="toggleModal"
      @prospect:listed="getWebsitesInOutreach(false, $event)"
      @prospect:created="getWebsitesInOutreach(true, $event)" />
  </BaseLayout>
</template>

<script>
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import Form from '@/components/form/Form'
import TableHeading from '@/components/table/TableHeading'
import TableDataCell from '@/components/table/TableDataCell'
import { mapGetters, mapState } from 'vuex'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import ActionLinks from '@/components/misc/ActionLinks'
import OutreachModal from '@/components/modal/OutreachModal'
import Input from '@/components/form/Input'
import _ from 'lodash'

export default {
  name: 'ScoreIndex',
  components: {
    Form,
    Table,
    Button,
    Input,
    Loading,
    Dropdown,
    BaseLayout,
    OutreachModal,
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell,
    'action-links': ActionLinks
  },
  data () {
    return {
      loading: false,
      practiceArea: '',
      location: '',
      formDisabled: false,
      formError: false,
      formErrors: [],
      sort: {
        key: 'arc_score',
        isAsc: true
      },
      marketingCompany: '',
      filteredLocations: [],
      websitesInOutreach: [],
      modalActive: false,
      selectedWebsite: '',
      modalMode: 'create',
      outreachCompanies: []
    }
  },
  created () {
    this.$store.dispatch('table/setTableData', {})
  },
  watch: {
    marketingCompany: {
      handler: function (val) {
        this.searchMarketingList(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    ...mapGetters({
      tableData: 'table/getTableData',
      hasOutreachKey: 'auth/hasOutreachKey'
    }),

    shareableSlug () {
      return this.getTaskSlug(
        this.tableData.completed_at,
        this.tableData.dfs_location_name,
        this.tableData.practice_area_name,
        this.tableData.id
      )
    },

    formattedLocation () {
      return Object.keys(this.tableData).length ? this.formatLocation(this.tableData.dfs_location_name) : ''
    },

    sortedResults () {
      const list = this.tableData.results ? this.tableData.results : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list.filter(result => !this.isBlacklisted(result.domain))
    }
  },
  mounted () {
    // setTimeout(() => {
    //   if (this.formData.practiceAreas.length === 0) {
    //     this.$store.dispatch('form/setData')
    //   }
    // }, 1000)
  },
  methods: {
    getArcRank () {
      var self = this
      if (this.sort.key === 'arc_score' && this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.arc_rank = i + 1
          result.domain_slug = self.getDomainSlug(
            self.tableData.completed_at,
            self.tableData.dfs_location_name,
            self.tableData.practice_area_name,
            result.domain,
            self.tableData.id
          )
        })
      }
    },

    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    enqueueTask (practiceArea, location) {
      this.$arc.post('tasks', {
        practice_area_name: `${practiceArea}`,
        dfs_location_name: `${location.replace(/, /g, ',')}`,
        domain_blacklist_enabled: true
      })
        .then(response => {
          const task = response.data
          this.waitForTask(task.id)
          this.loading = true
          this.formDisabled = true
        })
        .catch(error => {
          console.error(error)
          this.loading = false
          this.formDisabled = false
        })
    },

    randomFloatFromInterval (lowerBound = 5000, upperBound = 10000) {
      return Math.random() * (upperBound - lowerBound) + lowerBound
    },

    async waitForTask (id) {
      const now = new Date()

      try {
        const resp = await this.$arc.get(`tasks/${id}/status`)

        let shouldRetry = false

        const { created_at: createdAt, completed_at: completedAt } = resp.data

        shouldRetry = !completedAt && (now.getTime() - new Date(createdAt).getTime() < this.TEN_MINS)

        if (completedAt) {
          this.getTaskById(id)
        } else if (!shouldRetry) {
          this.formError = true
          this.formErrors = ['Report couldn\'t complete in under 10 minutes. Please check the archives at a later time.']
          this.loading = false
        }

        if (shouldRetry) {
          setTimeout(() => {
            this.waitForTask(id)
          }, this.randomFloatFromInterval())
        }
      } catch (error) {
        console.error('error fetching task status:', error)
        this.loading = false
      }
    },

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    getTaskById (id) {
      this.loading = true
      this.$arc.get(`tasks/${id}`)
        .then(res => {
          this.$store.dispatch('table/setTableData', res.data)
          this.getArcRank()
          this.tableData.results_excl_from_arc_score_calc = []
          this.formDisabled = false
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.formError = true
          this.formErrors = err
          this.loading = false
        })
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    updateMarketingAgency (task, marketingAgency) {
      this.$arc.put('domains', {
        name: `${task.domain}`,
        ...(marketingAgency.length >= 3 && { marketing_company: `${marketingAgency}` })
      })
        .then(response => {
          this.$arc.get(`domains/${response.data.name}`)
            .then(res => {
              this.$set(task, 'marketing_company', res.data.marketing_company)
              this.getBaseLayoutAlert(this).showAlert('Marketing company updated successfully', 'success')
            })
            .catch(err => {
              console.error(err)
              this.getBaseLayoutAlert(this).showAlert('Failed to update marketing company', 'error')
            })
          this.marketingCompany = ''
        })
        .catch(error => {
          console.error(error)
        })
    },

    runNewSearch () {
      this.location = ''
      this.practiceArea = ''
      this.formError = false
      this.$refs.taskSearch.$el.reset()
      this.$store.dispatch('table/setTableData', {})
    },

    isIncluded (resultId) {
      return !this.tableData.results_excl_from_arc_score_calc.includes(resultId)
    },

    excludeFromArc (domain, resultId) {
      this.$arc.post(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeInArc (domain, resultId) {
      this.$arc.delete(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeOrExclude (domain, resultId) {
      if (this.isIncluded(resultId)) {
        this.excludeFromArc(domain, resultId)
      } else {
        this.includeInArc(domain, resultId)
      }
    },

    getWebsitesInOutreach (created, outreachCompanies) {
      this.outreachCompanies = outreachCompanies.filter(company => {
        return company.status === 'prospect'
      })

      const webResults = []

      if (created) {
        webResults.push(outreachCompanies)
      } else {
        outreachCompanies.forEach(item => {
          webResults.push(item.websites)
        })
      }

      this.websitesInOutreach = webResults.flat().map(item => {
        const regexItem = item.replace(/https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/|/, '')
        const newStr = regexItem.replace('/', '')
        return newStr
      })
    },

    toggleModal (idx, edit, profile) {
      this.modalActive = !this.modalActive
      if (this.sortedResults[idx] !== undefined) {
        this.selectedWebsite = this.sortedResults[idx].domain
      }

      if (edit) {
        this.modalMode = 'edit'
      } else {
        this.modalMode = 'create'
      }
    },

    isIncludedInOutreach (domain, i) {
      if (i) {
        if (this.websitesInOutreach.includes(domain)) {
          const profile = this.outreachCompanies.find(company => {
            return company.websites.some(website => website.includes(domain))
          })
          if (profile) {
            this.sortedResults[i].outreachProfile = profile
          }
        }
      }
      return this.websitesInOutreach.includes(domain)
    }
  }
}
</script>

<style lang="scss">
.city {
  .action-links {
    display: flex;
  }
  form {
    margin-bottom: 30px;
  }
}
.validation-messages {
  a {
    color: #3b80e8;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
