<template>
  <div class="page-content">
    <Form
      class="filter-form"
      @submit.prevent="filterMarketingCompanies">
      <strong>Filters</strong>
      <Input
        :hasLabel="true"
        labelText="Name"
        placeholder="Company name"
        v-model="filterForm.companyName"
        :disabled="loading" />
      <Input
        :hasLabel="true"
        labelText="Url"
        placeholder="Company url"
        v-model="filterForm.companyUrl"
        :disabled="loading" />
      <Button
        type="submit"
        text="Apply filters"
        :isDisabled="loading" />
    </Form>
    <settings-panel
      :actionLinkMethod="toggleFormModal">
      <template slot="modal">
        <div
          class="form-modal"
          :class="{ show: formModalActive }"
          >
          <div class="form-modal-wrapper"
            :style="[
              loadedMarketingCompanies
                ? {
                  maxWidth: '800px'
                }
                : {}
            ]">
            <h3>
              {{ formModalMode === 'edit' ? 'Edit' : 'Add New' }} Marketing Company
            </h3>
            <Form
              v-if="!loadedMarketingCompanies">
              <Loading
                v-show="loading"
                text="Loading company data" />
              <div class="field-group" v-show="!loading">
                <Input
                  :hasLabel="true"
                  labelText="Company Icon"
                  placeholder="Company icon"
                  inputId="companyIcon"
                  type="file"
                  :fancyFile="true"
                  :disabled="loading"
                  @change="handleFileChange($event)"
                  v-if="formModalMode === 'edit'" />
                <Input
                  type="text"
                  :hasLabel="true"
                  labelText="Company Name"
                  placeholder="company name"
                  :required="formModalMode !== 'edit'"
                  v-model="form.companyName" />
                <Input
                  type="url"
                  :hasLabel="true"
                  labelText="Company Url"
                  placeholder="company url"
                  v-model="form.companyUrl" />
                <Input
                  type="text"
                  :hasLabel="true"
                  labelText="Snippet"
                  placeholder="snippet"
                  v-model="form.companySnippet" />
              </div>
            </Form>
            <div
              class="form-file-upload"
              v-if="!loadedMarketingCompanies && formModalMode === 'add'">
              <Form style="margin-top:20px;">
                <Input
                  type="file"
                  :hasLabel="true"
                  labelText="Upload csv"
                  inputId="uploadCsv"
                  @change="uploadMktCsv($event)"
                  style="flex-basis:95px;width:auto;" />
                <a
                  style="font-size:13px;text-decoration:underline;cursor:pointer;color:inherit;"
                  href="/mkt_example.csv"
                  download="mkt_example.csv">
                  (Download Example)
                </a>
              </Form>
            </div>
            <div
              class="table-scroll-container"
              v-if="loadedMarketingCompanies">
              <Table>
                <template slot="head">
                  <t-row>
                    <t-heading>
                      Name
                    </t-heading>
                    <t-heading>
                      URL
                    </t-heading>
                    <t-heading>
                      Snippet
                    </t-heading>
                  </t-row>
                </template>
                <template slot="body">
                  <t-row
                    v-for="(company, idx) in loadedMarketingCompanies"
                    :key="idx">
                    <t-cell>
                      {{ company.name }}
                    </t-cell>
                    <t-cell>
                      {{ company.url }}
                    </t-cell>
                    <t-cell>
                      {{ company.snippet }}
                    </t-cell>
                  </t-row>
                </template>
              </Table>
            </div>
            <button-group
              class="form-buttons"
              btnAlign="right">
              <Button
                text="Cancel"
                :isInversed="true"
                :isBordered="true"
                @click.prevent="closeFormModal" />
              <Button
                text="Submit"
                :isDisabled="loading"
                btnType="primary"
                @click.prevent="handleFormSubmit" />
            </button-group>
          </div>
        </div>
      </template>
      <Table v-if="marketingCompanies.length > 0">
        <template slot="head">
          <t-row>
            <t-heading
              :noPadding="true"
              style="width:50px;"></t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              Name
            </t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              URL
            </t-heading>
            <t-heading></t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(company, idx) in marketingCompanies"
            :key="idx">
            <t-cell
              :noPadding="true"
              style="width:50px;">
              <div class="logo-container">
                <img
                  height="24"
                  width="24"
                  :src="company.favicon_url || company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              </div>
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="company.name"
              @editInput="form.companyName = $event"
              @doneEditing="updateMarketingCompany(company.id, idx)">
              {{ company.name }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="company.url"
              @editInput="form.companyUrl = $event"
              @doneEditing="updateMarketingCompany(company.id, idx)">
              <a
                :href="company.url"
                target="_blank">
                {{ company.url }}
              </a>
            </t-cell>
            <t-cell>
              <div class="table-action">
                <span
                  class="table-action-icon"
                  @click.prevent="toggleTableAction($event)">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <ul>
                  <li @click="toggleFormModal('edit', company.id)">
                    Edit
                  </li>
                  <li @click="deleteMarketingCompany(idx)">
                    Delete
                  </li>
                </ul>
              </div>
            </t-cell>
          </t-row>
        </template>
      </Table>
      <Loading
        v-show="loading"
        text="Loading overview" />
      <p v-if="marketingCompanies.length === 0 && !loading">
        No results found. Please try again.
      </p>
      <Pagination
        v-if="!loading"
        :perPage="pagination.per_page"
        :currentPage="pagination.page"
        :nextNum="pagination.next_num"
        :prevNum="pagination.prev_num"
        :totalItems="pagination.total"
        @pageChanged="goToPage($event)" />
    </settings-panel>
    <Modal
      :showModal="logModalActive"
      class="log-modal"
      @close="closeLogModal">
      <template slot="header">
        <h2>Bulk import results</h2>
      </template>
      <template slot="body">
        <Accordions>
          <Accordion
            :title="`${successes.length ? successes.length : 0} successful entries`">
            <ul>
              <li
                v-for="(success, idx) in successes"
                :key="idx">
                {{ success }}
              </li>
            </ul>
          </Accordion>
          <Accordion
            :title="`${errors.length ? errors.length : 0} errors`">
              <ul>
                <li
                  v-for="(error, idx) in errors"
                  :key="idx">
                  {{ error }}
                </li>
              </ul>
          </Accordion>
        </Accordions>
      </template>
    </Modal>
  </div>
</template>

<script>
import Loading from '@/components/misc/Loading'
import SettingsPanel from '@/components/global/user/SettingsPanel'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import Pagination from '@/components/misc/Pagination'
import Modal from '@/components/modal/Modal'
import Accordions from '@/components/global/accordion/Accordions'
import Accordion from '@/components/global/accordion/Accordion'
const csv = require('csvtojson')

export default {
  name: 'UserMarketingCompanies',
  components: {
    Loading,
    SettingsPanel,
    Table,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    Button,
    ButtonGroup,
    Form,
    Input,
    Pagination,
    Modal,
    Accordion,
    Accordions
  },
  data () {
    return {
      loading: false,
      marketingCompanies: [],
      selectedKeywords: [],
      formModalActive: false,
      formModalMode: 'add',
      currentMarketingCompany: null,
      filterForm: {
        companyName: '',
        companyUrl: ''
      },
      form: {
        companyName: '',
        companyUrl: '',
        companySnippet: '',
        companyIcon: null
      },
      pagination: {
        page: 1,
        next_num: 2,
        prev_num: null,
        per_page: 50,
        total: 0
      },
      loadedMarketingCompanies: null,
      errors: [],
      successes: [],
      logModalActive: false
    }
  },
  mounted () {
    this.getMarketingCompanies()
  },
  methods: {
    handleFileChange (evt) {
      const file = evt.target.files[0]
      if (!file) {
        console.error('No file selected.')
        return
      }

      const reader = new FileReader()

      reader.onload = (event) => {
        this.form.companyIcon = {
          filename: file.name,
          b64hex_bin_data: this.getHexBinData(reader.result)
        }
      }

      reader.readAsDataURL(file)
    },

    handleFormSubmit () {
      this.formModalMode === 'edit'
        ? this.updateMarketingCompany(this.currentMarketingCompany.id, null)
        : this.addNewMarketingCompany()
    },

    async getMarketingCompanies (pageNum, nameLike, urlLike) {
      this.loading = true
      try {
        const { data } = await this.$arc.get('marketing_companies', {
          params: {
            per_page: this.pagination.per_page,
            ...{ page: pageNum || this.pagination.page },
            ...(nameLike ? { name_like: nameLike } : {}),
            ...(urlLike ? { url_like: urlLike } : {}),
            strict: 0
          }
        })
        this.marketingCompanies = data?.marketing_companies
        this.pagination.page = data?.page
        this.pagination.next_num = data?.next_num
        this.pagination.prev_num = data?.prev_num
        this.pagination.per_page = data?.per_page
        this.pagination.total = data?.total
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },

    closeLogModal () {
      this.logModalActive = false
      this.errors = []
      this.successes = []
    },

    async addNewMarketingCompany () {
      this.loading = true
      if (this.loadedMarketingCompanies?.length) {
        let formattedCompanies = this.loadedMarketingCompanies.map(company => {
          return {
            ...company,
            url: this.formatUrl(company.url)
          }
        })

        while (formattedCompanies.length > 0) {
          try {
            const response = await this.$arc.post('marketing_companies', formattedCompanies)
            response.data.forEach(mkt => this.successes.push(`Marketing company '${mkt.name}' successfully added`))
            this.closeFormModal()
            this.loading = false
            break
          } catch (error) {
            if (error.response?.data?.validation_error?.body_params) {
              const failedCompanies = []

              error.response.data.validation_error.body_params.forEach(err => {
                if (err.type === 'assertion_error') {
                  const regex = /Marketing company name '(.+?)' is already taken/
                  const match = err.msg.match(regex)

                  if (match) {
                    const companyName = match[1]

                    const failedCompany = formattedCompanies.find(
                      (company) => company.name === companyName
                    )

                    if (failedCompany) {
                      this.errors.push(err.msg)
                      failedCompanies.push(failedCompany)
                    }
                  }
                }
              })

              formattedCompanies = formattedCompanies.filter(
                (company) => !failedCompanies.includes(company)
              )
            } else {
              console.error('An error ocurred: ', error)
              break
            }
          }
        }

        if (this.errors.length > 0) {
          console.info('Errors for the following companies', this.errors)
          this.logModalActive = true

          this.closeFormModal()
          this.loading = false
        } else {
          this.getBaseLayoutAlert(this.$parent).showAlert('Successfully added bulk marketing companies', 'success')
        }
      } else {
        try {
          const marketingCompany = [
            {
              name: this.form.companyName,
              ...(this.form.companyUrl && { url: this.formatUrl(this.form.companyUrl) }),
              ...(this.form.companySnippet && { snippet: this.form.companySnippet })
            }
          ]
          await this.$arc.post('marketing_companies', marketingCompany)
          this.getBaseLayoutAlert(this.$parent).showAlert('Successfully added new marketing company', 'success')

          this.closeFormModal()
          setTimeout(() => {
            this.getMarketingCompanies()
          }, 2000)
        } catch (error) {
          const errorRes = error.response?.data
          if (errorRes.validation_error.body_params) {
            errorRes.validation_error.body_params.forEach(err => {
              this.getBaseLayoutAlert(this.$parent).showAlert(err.msg, 'error')
            })
          } else {
            const alertMessage = this.loadedMarketingCompanies?.length
              ? 'An error occurred while adding new marketing companies'
              : 'An error occurred while new marketing company'
            this.getBaseLayoutAlert(this.$parent).showAlert(alertMessage, 'error')
          }
          console.error(error)
        } finally {
          this.loading = false
        }
      }
    },

    async getMarketingCompany (id) {
      this.loading = true
      try {
        const { data } = await this.$arc.get(`marketing_companies/${id}`)
        this.currentMarketingCompany = data?.marketing_company
        this.form.companyName = data?.marketing_company?.name
        this.form.companyUrl = data?.marketing_company?.url
        this.form.companySnippet = data?.marketing_company?.snippet
        this.loading = false
      } catch (error) {
        this.getBaseLayoutAlert(this.$parent).showAlert('An error occurred while fetching the marketing company.', 'error')
        console.error(error)
        this.loading = false
      }
    },

    async updateMarketingCompany (id, idx) {
      if (id) {
        try {
          this.loading = true
          const res = await this.$arc.patch(`marketing_companies/${id}`, {
            ...(this.form.companyName && { name: this.form.companyName }),
            ...(this.form.companyUrl && { url: this.form.companyUrl }),
            ...(this.form.companySnippet && { snippet: this.form.companySnippet })
          })

          if (this.form.companyIcon) {
            try {
              await this.$arc.put(`marketing_companies/${id}/icon`, this.form.companyIcon)
            } catch (err) {
              console.error(err)
            }
          }

          this.loading = false
          if (idx) {
            this.$set(this.marketingCompanies[idx], 'name', res.data?.name)
            this.$set(this.marketingCompanies[idx], 'url', res.data?.url)
          }
          this.getBaseLayoutAlert(this.$parent).showAlert('Successfully updated the marketing company', 'success')
          this.closeFormModal()
          setTimeout(() => {
            this.getMarketingCompanies()
          }, 2000)
        } catch (error) {
          const errorRes = error.response?.data
          if (errorRes.validation_error.body_params) {
            errorRes.validation_error.body_params.forEach(err => {
              this.getBaseLayoutAlert(this.$parent).showAlert(err.msg, 'error')
            })
          } else {
            this.getBaseLayoutAlert(this.$parent).showAlert('An error occurred while updating the marketing company', 'error')
          }
          console.error(error)
          this.loading = false
        }
      }
    },

    async deleteMarketingCompany (idx) {
      if (confirm('Are you sure you want to delete this marketing company?')) {
        const marketingCompanyToDelete = this.marketingCompanies[idx]

        if (marketingCompanyToDelete.id) {
          try {
            await this.$arc.delete(`marketing_companies/${marketingCompanyToDelete.id}`)
            this.marketingCompanies.splice(idx, 1)
            this.$forceUpdate()
            this.getBaseLayoutAlert(this.$parent).showAlert('Successfully deleted the marketing company', 'success')
            setTimeout(() => {
              this.getMarketingCompanies()
            }, 2000)
          } catch (error) {
            this.getBaseLayoutAlert(this.$parent).showAlert('An error occurred while deleting the marketing company', 'success')
            console.error(error)
          }
        }
      }
    },

    uploadMktCsv (evt) {
      const file = evt.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        let csvData = e.target.result

        if (csvData.charCodeAt(0) === 0xFEFF) {
          csvData = csvData.slice(1)
        }

        csv()
          .fromString(csvData)
          .then(obj => {
            const marketingCompanies = []

            obj.forEach(item => {
              const { name, url } = item
              const sanitizedUrl = url.charCodeAt(0) === 65533 ? url.slice(1) : url

              if (!marketingCompanies.some(company => company.name === name)) {
                marketingCompanies.push({ ...item, url: sanitizedUrl })
              }
            })

            this.loadedMarketingCompanies = marketingCompanies
          })
          .catch(error => {
            console.error(error)
            this.getBaseLayoutAlert(this.$parent).showAlert('An error occurred while parsing the csv file', 'error')
          })
      }

      reader.onerror = () => {
        this.getBaseLayoutAlert(this.$parent).showAlert('An error occurred while reading the file', 'error')
      }

      reader.readAsText(file, 'UTF-8')
    },

    closeFormModal () {
      this.formModalMode = 'add'
      this.formModalActive = false
      this.form = {
        companyName: '',
        companyUrl: '',
        companySnippet: ''
      }
      this.loadedMarketingCompanies = null
    },

    toggleFormModal (mode, id) {
      if (id) this.getMarketingCompany(id)
      mode === 'edit' ? this.formModalMode = 'edit' : this.formModalMode = 'add'
      this.formModalActive = !this.formModalActive
    },

    toggleTableAction (evt) {
      const icon = evt.target.closest('.table-action-icon')
      const ul = icon.nextElementSibling
      ul.classList.toggle('show')
    },

    filterMarketingCompanies () {
      this.getMarketingCompanies(this.pagination.page, this.filterForm.companyName, this.filterForm.companyUrl)
    },

    goToPage (evt) {
      this.getMarketingCompanies(evt)
    }
  }
}
</script>

<style lang="scss">
section {
  &.user-profile {
    .container {
      .filter-form {
        margin-bottom: 20px;

        > strong {
          margin-bottom: 16px;
          margin-right: 16px;
        }
      }
      .form-modal {
        &-wrapper {
          max-width: 600px;
        }
      }
      table {
        .logo-container {
          img {
            margin: 0 1rem;
            vertical-align: bottom;
            display: inline-block;
          }
        }
      }
      .form-buttons {
        margin-top: 30px;
      }
      .table-scroll-container {
        position: relative;
        max-height: 35vh;
        overflow-y: auto;
        overflow-x: clip;
      }
    }
    .accordions {
      margin-block: 20px 0;
      width: 100%;
    }
    .log-modal {
      .modal-card {
        height: 500px;
        min-height: unset;
        padding-bottom: 40px;
      }
    }
  }
}
</style>
